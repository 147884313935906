
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Quiz from './components/Quiz';
import Home from './components/Home';
import Nav from './components/Nav';
import ComingSoon from './components/ComingSoon';
function App() {
    return (
        <>
        <Router>
        <Nav/>
        
            <Routes>
              
                <Route path="/" element={<Home />} />
                <Route path="/GetStarted" element={<Quiz />} />
            <Route path="/Blog" element={<ComingSoon />} />
            </Routes>
        </Router>
        </>
    );
}

export default App;

