import React, { useState } from "react";

function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizData, setQuizData] = useState([
    {
      question: "Question 1: What is your budget for the project?",
      answers: ["Low", "Medium", "High"],
      selectedAnswer: null,
    },
    {
      question:
        "Question 2: What type of construction project are you planning?",
      answers: ["New Construction", "Remodel", "Add-On"],
      selectedAnswer: null,
    },
    {
      question: "Question 3: What is the size of the project?",
      answers: ["Small", "Medium", "Large"],
      selectedAnswer: null,
    },
    {
      question: "Question 4: What is the timeline for the project?",
      answers: ["ASAP", "1-3 Months", "3-6 Months", "6+ Months"],
      selectedAnswer: null,
    },
    {
      question: "Question 5: What is the status of the project?",
      answers: ["Planning & Budgeting", "Ready to Hire", "Ready to Start"],
      selectedAnswer: null,
    },
  ]);

  const handleAnswerSelect = (answer) => {
    // Update the selected answer for the current question
    const updatedQuizData = [...quizData];
    updatedQuizData[currentQuestion].selectedAnswer = answer;
    setQuizData(updatedQuizData);
  };

  const [userEmail, setUserEmail] = useState("");
  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const nextQuestion = () => {
    if (currentQuestion < quizData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("Quiz is complete");
      const results = {
        email: userEmail,
        quizAnswers: quizData.map((question) => ({
          question: question.question,
          selectedAnswer: question.selectedAnswer,
        })),
      };

      console.log("Results:", results);

      fetch(`${process.env.REACT_APP_API_URL}/api/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(results),
      })
      .then((response) => {
        if (response.ok) {
          alert('Your quiz results have been submitted successfully!');
        }
      });
      
    }
  };

  return (
    <div className="bg-black">
      <div className="max-w-lg mx-auto my-10 p-6 rounded-lg shadow-lg bg-white">
        {quizData[currentQuestion] && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">
              {quizData[currentQuestion].question}
            </h2>
            <form className="space-y-4">
              {quizData[currentQuestion].answers.map((answer, index) => (
                <label key={index} className="flex items-center">
                  <input
                    type="radio"
                    name="answer"
                    value={answer}
                    checked={
                      quizData[currentQuestion].selectedAnswer === answer
                    }
                    onChange={() => handleAnswerSelect(answer)}
                    className="mr-2"
                  />
                  <span className="text-lg">{answer}</span>
                </label>
              ))}
            </form>
          </div>
        )}

        <input
          type="email"
          placeholder="Enter your email"
          value={userEmail}
          onChange={handleEmailChange}
          className="w-full mt-6 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          onClick={nextQuestion}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg mt-6 hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Quiz;
