import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <div className="flex h-screen">
      <div className="m-auto text-7xl font-bold text-center text-gray-600">
        Coming Soon!
      </div>
    </div>
  );
};

export default ComingSoon;
