import React from "react";

function Nav() {
  return (
      <header className="bg-black ">
  <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
    <div className="flex h-16 items-center justify-between">
      <div className="md:flex md:items-center md:gap-12">
      <a className="block " href="/">
            <span className="sr-only">Home</span>
            <img src="/logo.png" alt="Logo" className="h-64 w-64" />
          </a>
      </div>

      <div className="hidden md:block">
        <nav aria-label="Global">
          <ul className="flex items-center gap-6 text-sm">
            <li>
              <a
                className="text-gray-300 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                href="/"
              >
                Home
              </a>
            </li>

            <li>
              <a
                className="text-gray-300 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                href="/"
              >
                About
              </a>
            </li>

            <li>
              <a
                className="text-gray-300 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                href="/"
              >
                Our Services
              </a>
            </li>

            <li>
              <a
                className="text-gray-300 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                href="/Blog"
              >
                Projects
              </a>
            </li>

            <li>
              <a
                className="text-gray-300 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                href="/Blog"
              >
                Blog
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="flex items-center gap-4">
        <div className="sm:flex sm:gap-4">
        

          <div className="hidden sm:flex">
            <a
              className="rounded-md bg-gray-600 px-5 py-2.5 text-sm font-medium text-white dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
              href="/GetStarted"
            >
              Get Started
            </a>
          </div>
        </div>

        <div className="block md:hidden">
          <button
            className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75 dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
  );
}

export default Nav;
