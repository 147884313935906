import React from "react";
import "../styles/Banner.css";
import { Link } from "react-router-dom";


function Banner() {
  return (
    <section className="bg-black  ">
      <div className="banner relative bg-black md:flex md:flex-row overflow-x-hidden">
        <div className="slogan-container py-32 px-3 text-center md:flex-1 md:flex md:flex-col md:justify-center md:items-center">
          <h1 className="slogan text-center text-7xl font-bold  text-gray-600 sm:text-7xl">
            Building Excellence, One Frame at a Time.
          </h1>
          <h1 className="text-[#808080] py-3">Get a quote as soon as 24 hours</h1>
          <Link
            to="/GetStarted"
            className="mt-2 inline-flex items-center gap-2 rounded bg-gray-600 px-8 py-3 text-gray-100 transition hover:text-gray-600/75 dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
          >
            <span className="text-sm font-medium">Get Started</span>
            <svg
              className="h-5 w-5 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </Link>
        </div>
        <div className="relative md:flex-1">
          <div className="flex justify-center  bg-black items-center">
            <img
              src="./Framing.png"
              alt="Descriptive Alt Text"
            
            />
          </div>
        </div>
      </div>
     
      <span className="relative flex justify-center">
  <div
    className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
  ></div>

 
</span>
    </section>

  );
}


export default Banner;
