import React from "react";

interface StepProps {
  title: string;
  description: string;
}

const Step: React.FC<StepProps> = ({ title, description }) => {
  return (
    <div className="flex flex-col items-center">
      <h3 className="mt-0.5 text-2xl font-large text-gray-900">{title}</h3>
      <p className="mt-4 text-gray-700">{description}</p>
      
    </div>
  );
};


const Howto: React.FC = () => {
  return (
    <article className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16 rounded-xl bg-white shadow-md">
      <div className="text-center">
        <h1 className="text-center text-4xl font-bold tracking-tight text-gray-600 sm:text-5xl">How to Get Started</h1>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center mt-9 border-t border-b py-4">
        <Step title="Step 1" description="Click on the &quot;Get Started&quot; button above." />
        <div className="border-t sm:border-r  h-6 sm:border-b sm:h-0"></div>
        <Step title="Step 2" description="Answer the short questionnaire about your project." />
        <div className="border-t sm:border-r h-6 sm:border-b sm:h-0"></div>
        <Step title="Step 3" description="Sit back and wait for an email!" />
      </div>
    </article>
  );
};


export default Howto;


