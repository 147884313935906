import React from 'react';
import Banner from './Banner';
import HowTo from './HowTo';
import Options from './Options';
import "../styles/Home.css";
import Testimonial from './Testimonial';
import Footer from './Footer';
export default function Home() {
    return (
        <div className='bg-black'>
            <Banner/>
            <Options/>
            <HowTo/>
            <Testimonial/>
            <Footer/>
        </div>
    );
}

